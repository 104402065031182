<!--
 * @Author: faf
 * @desc: 区域收入对比分析
 * @Date: 2022-07-07 11:21:02
 * @LastEditors: faf
 * @ LastEditTime: 
-->
<template>
  <div class="content-wrapper">
    <div id="analysisIncomeChart" class="chart"></div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: {
    chartParam: {
      default: {}
    }
  },
  watch: {
    chartParam: {
      handler(newVal, oldVal) {
        this.legendData = [];
        this.legendList = this.uniq(newVal.legendData);
        this.legendData = this.uniq(newVal.legendData);
        this.legendList.unshift('product');
        // source: [
        //     { product: '2017', 区域1: 43.3, 区域2: 85.8, 区域3: 93.7 },
        //     { product: '2018', 区域1: 83.1, 区域2: 73.4, 区域3: 55.1 },
        //     { product: '2019', 区域1: 86.4, 区域2: 65.2, 区域3: 82.5 },
        //     { product: '2020', 区域1: 72.4, 区域2: 53.9, 区域3: 39.1 }
        //   ]
          let arr = [];
          // this.legendData.forEach(leg => {
            newVal.barData.forEach(bar => {
              let obj = {};
              for (let i in bar) {
                obj['product'] = i;
                bar[i].forEach(barItem => {
                  obj[barItem.areaName] = barItem.actualPay ? (barItem.actualPay / 100) : '0.00';
                })
              }
              arr.push(obj);
            })
          // })
          this.sourceList = arr;
        this.drawGraph();
      },
      deep: true
    }
  },
  data() {
    return {
      dataDetailList: [],
      legendList: [],
      sourceList: [],
      legendData: []
    };
  },
  methods: {
    uniq(array) {
      var temp = [];
      for (var i = 0; i < array.length; i++) {
        if (temp.indexOf(array[i]) == -1) {
          temp.push(array[i]);
        }
      }
      return temp;
    },

    drawGraph() {
      let seriesData = [];
      let legendList = [];
      this.chartParam.barData && this.chartParam.barData.forEach((element, ind) => {
        // let dataList = [];
        for (let i in element) {
          element[i].forEach(val => {
            // dataList.push(val.actualPay ? val.actualPay : 0);
            legendList.push(val.areaName);
          });
        }
      });
      this.legendData.forEach(leng => {
        seriesData.push({
            name: leng,
            type: "bar",
            // barGap: 0,
            // barGap: this.chartParam.xData.length > 15 ? 23 : 0,
            // barCategoryGap: 15,
            label: {
              show: false,
              position: "insideBottom",
              distance: 15,
              align: "left",
              verticalAlign: "middle",
              rotate: 90,
              formatter: "{c}  {a}",
              fontSize: 12,
              color: "#fff"
            },
            // barWidth: this.chartParam.xData.length > 15 ? 19 : 2,
            // barMinWidth: 2,
            barMaxWidth: 19,
            itemStyle: {
              borderRadius: 2
            },
            // emphasis: {
            //   focus: "series"
            // },
            // data: dataList,
            // encode: {
            //   x: ['dimensions'],
            //   y: ['source']
            // }
        })
      })
      this.dataDetailList = seriesData;
      let option = {
        color: ["#527BFF", "#95AEFF", "#CAE9FB", "#9A95FF", "#FF7E08", "#AE7CE6", "#DDFF95"],
        title: {
          text: "单位：元",
          color: "#9AA4B3",
          top: 22,
          left: "26px",
          textStyle: {
            fontSize: 12,
            fontWeight: "400",
            color: "#9AA4B3"
          }
        },
        dataset: {
          dimensions: this.legendList,
          source: this.sourceList
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          },
          // formatter: data => {
          //   console.log('nnnnn', data);
          //   let html = "";
          //   data.forEach(el => {
          //     html = `${el.name}</br>`;
          //     for (let i in el.data) {
          //       if (i !== 'product') {
          //         html += `${i}: ${el.seriesName}</br>`;
          //       }
          //     }
          //   })
          //   return html;
          // }
        },
        grid: {
          top: "50px",
          bottom: "16px",
          left: "30px",
          right: "26px",
          containLabel: true
        },
        legend: {
          type: "scroll",
          itemWidth: 16,
          itemHeight: 5,
          // left: '15%',
          top: 0,
          right: "16px",
          textStyle: {
            color: "#9AA4B3"
          },
          data: this.legendData
        },
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#9AA4B3"
              }
            },
            axisLabel: {
              margin: 16
            },
            // data: this.chartParam.xData
          }
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed"
              }
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#9AA4B3"
              }
            }
          }
        ],
        series: this.dataDetailList
      };
      let chart;
      chart = this.$echarts.init(
        document.getElementById("analysisIncomeChart")
      );
      chart.clear();
      chart.setOption(option);
      window.addEventListener("resize", () => {
        option && chart.resize();
      });
    }
  },
  mounted() {
    this.drawGraph();
  }
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.content-wrapper {
  width: 100%;
  height: 262px;
}

.chart {
  width: 100%;
  height: 262px;
}
</style>