<!--
 * @Author: faf
 * @desc: 区域收入占比
 * @Date: 2022-07-07 11:21:02
 * @LastEditors: faf
 * @ LastEditTime: 
-->
<template>
  <div class="content-wrapper">
    <div id="chartData" class="chart"></div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: {
    chartParam: {
      default: {}
    }
  },
  watch: {
    chartParam: {
      handler(newVal, oldVal) {
        this.drawGraph();
      },
      deep: true
    }
  },
  data() {
    return {};
  },
  methods: {
    drawGraph() {
      let option = {
        color: ["#5A90FF", "#95AEFF", "#AE7CE6", "#FF7E08", "#EBF8FF", "#9A95FF", "#DDFF95"],
        title: {
          subtext: this.chartParam.textData.toString().length >= 8 ? 
            (this.chartParam.textData / 100 / 10000).toFixed(2).split(".")[0].replace(/(?=(\B)(\d{3})+$)/g, ",") + "." + (Number(this.chartParam.textData) / 100 / 10000).toFixed(2).split(".")[1] + '万元'
            : (this.chartParam.textData / 100).toFixed(2).split(".")[0].replace(/(?=(\B)(\d{3})+$)/g, ",") + "." + (this.chartParam.textData / 100).toFixed(2).split(".")[1] + '元',
          text: "总收入",
          left: "center",
          top: "32%",
          textStyle: {
            fontFamily: "MicrosoftYaHei",
            fontSize: 12,
            color: "#425466"
          },
          subtextStyle: {
            fontFamily: "MicrosoftYaHei-Bold, MicrosoftYaHei",
            fontSize: 16,
            color: "#425466",
            align: "center",
            fontWeight: "bold"
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: (val) => {
            let html = '';
            html += `${val.name}</br>收入：${
              val.value.toString().length >= 8 ? 
            (val.value / 100 / 10000).toFixed(2).split(".")[0].replace(/(?=(\B)(\d{3})+$)/g, ",") + "." + (Number(val.value) / 100 / 10000).toFixed(2).split(".")[1] + '万元'
            : (val.value / 100).toFixed(2).split(".")[0].replace(/(?=(\B)(\d{3})+$)/g, ",") + "." + (val.value / 100).toFixed(2).split(".")[1] + '元'
            }`
            return html;
          }
        },
        legend: {
          type: "scroll",
          bottom: 0,
          padding: [0, 16, 0],
          itemWidth: 16,
          itemHeight: 5,
          itemGap: 24,
          textStyle: {
            color: "#9AA4B3"
          }
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true }
          }
        },
        series: [
          {
            // name: "Nightingale Chart",
            type: "pie",
            radius: [50, 100],
            center: ["50%", "40%"],
            roseType: "area",
            itemStyle: {
              // borderRadius: 8
            },
            label: {
              color: "#425466"
            },
            // labelLine: {
            //   length: 0,
            //   length2: 0
            // },
            data: this.chartParam.pieData
          }
        ]
      };
      let chart;
      chart = this.$echarts.init(document.getElementById("chartData"));
      chart.setOption(option);
      window.addEventListener("resize", () => {
        option && chart.resize();
      });
    }
  },
  mounted() {
    // this.drawGraph();
  }
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.content-wrapper {
  width: 100%;
  height: 290px;
}

.chart {
  width: 100%;
  height: 270px;
}
</style>